export default function (instance) {
  return {
    getBuyer() {
      return instance.post("api/get-buyer-api");
    },

    creatOrUpdateBuyer(payload) {
      return instance.post("api/creat-or-update-buyer-api", payload);
    },
  };
}
