<template>
  <Navigation></Navigation>
  <div class="top-manager"></div>
  <div class="container">
    <div class="header-top">
      <div class="row">
        <div class="col-md-6 col-sm-6 col-12 mb-2">
          <div class="header-top-left">
            <i class="fad fa-phone"></i> {{ infoPhone }} <br />
            <i class="fad fa-mail-bulk"></i> {{ infoEmail }}
          </div>
        </div>
        <Loader v-if="loading" class="col-md-6 col-sm-6 col-12"></Loader>
        <div class="col-md-6 col-sm-6 col-12 mb-2">
          <div class="search-box-view">
            <form @submit.prevent="submitHandler">
              <input
                v-model="q"
                class="email"
                placeholder="Поиск продукта"
                type="text"
              />
              <button class="submit" type="submit"></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/elements/Navigation";
// import Cart from "@/components/elements/Cart";
// import MenuSettings from "@/components/elements/MenuSettings";
import { mapGetters } from "vuex";

export default {
  components: {
    Navigation
    // Cart,
    // MenuSettings,
  },
  data() {
    return {
      loading: false,
      api: "",
      search: null,
      q: null
    };
  },

  computed: {
    infoPhone() {
      // return this.$store.getters.params.infoPhone;
      return "8-800-200-58-90";
    },
    infoEmail() {
      return this.$store.getters.params.infoEmail;
    },
    ...mapGetters({
      CART: "products/CART"
    })
  },

  async mounted() {
  },

  methods: {
    async submitHandler() {
      try {
        if (this.q) {
          this.loading = true;
          this.search = (await this.$api.search.searchRes(this.q)).data;
          this.loading = false;
          if (!this.search.length) {
            this.$toast.warning(`Поиск не дал результатов`);
          } else {
            this.$router.push("/search-result/" + this.q);
            this.q = null;
            this.search = null;
          }
        } else {
          this.$toast.warning(`Вы ничего не ввели`);
        }
      } catch (e) {
        throw `${e}`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.top-manager {
  display: block;
  width: 100%;
  padding: 50px;
}

@media (min-width: 853px) {
  .top-manager {
    //padding: 45px;
  }
  //.cart-mobile {
  //  display: none;
  //}
}
</style>
