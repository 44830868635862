export default function (instance) {
  return {
    estimateCheckPrice(payload) {
      return instance.post("api-yandex/estimate-check-price", payload);
      // return instance.post(
      //   "api-yandex/create-new-order-from-yandex-delivery",
      //   payload
      // );
      // .then((res) => {
      //   this.$store
      //     .dispatch("products/setProducts", res.data)
      //     .then(() => {
      //       console.log(res);
      //     });
      // })
      // .catch((err) => {
      //   console.log(err + " Хрень какая-то происходит");
      // });
    },

    createDeliveryOrder(payload) {
      return instance.post(
        "api-yandex/create-new-order-from-yandex-delivery",
        payload
      );
    },

    getDeliveryStatus(payload) {
      return instance.post("api-yandex/yandex-delivery-status", payload);
    },
  };
}
