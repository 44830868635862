// import lazyLoadFunction from "../utils/lazyLoadFunction";

export default {
  install: (app) => {
    app.mixin({
      created() {
        window.addEventListener("scroll", this.lazyScroll);
      },
      methods: {
        //Докрутил ли пользователь до картинки
        lazyScrollCheck() {
          this.imgIndex = this.lazyImagesPositions.findIndex(
            (item) => pageYOffset > item - this.windowHeight - 1600
          );
          if (this.imgIndex >= 0) {
            if (this.lazyImages[this.imgIndex].dataset.src) {
              this.lazyImages[this.imgIndex].src =
                this.lazyImages[this.imgIndex].dataset.src;
              this.lazyImages[this.imgIndex].removeAttribute("data-src");
            } else if (this.lazyImages[this.imgIndex].dataset.srcset) {
              this.lazyImages[this.imgIndex].srcset =
                this.lazyImages[this.imgIndex].dataset.srcset;
              this.lazyImages[this.imgIndex].removeAttribute("data-srcset");
            }
            delete this.lazyImagesPositions[this.imgIndex];
          }
        },
        lazyScroll() {
          if (
            document.querySelectorAll("img[data-src], source[data-srcset]")
              .length > 0
          ) {
            this.lazyScrollCheck();
          }
        },
      },
      mounted() {
        this.lazyImages = document.querySelectorAll(
          "img[data-src], source[data-srcset]"
        );
        this.windowHeight = document.documentElement.clientHeight;
        this.lazyImagesPositions = [];
        if (this.lazyImages.length > 0) {
          this.lazyImages.forEach((img) => {
            if (img.dataset.src || img.dataset.srcset) {
              this.lazyImagesPositions.push(
                img.getBoundingClientRect().top + pageYOffset
              );
              this.lazyScrollCheck();
            }
          });
        }
      },
    });
  },
};
