<template>

  <div class="app-main-layout">
    <Header></Header>
    <!--    <GlobalLoader v-if="loading"></GlobalLoader>-->
    <div class="container" style="min-height: 75vh">
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/app/Header";
import Footer from "@/components/app/Footer";

import messages from "@/utils/messages";

export default {
  name: "main-layout",
  data: () => ({
    loading: true,
    search: ""
  }),

  components: {
    Header,
    Footer
  },

  computed: {
    // error() {
    //   return this.$store.getters.error;
    // },
  },
  watch: {
    // error(fbError) {
    //   this.$error(messages[fbError.code] || 'Что-то пошло не так!');
    // },
  },

  methods: {},

  async mounted() {
    //   if (!Object.keys(this.$store.getters.info).length) {
    //     await this.$store.dispatch('fetchInfo');
    //   }

    if (messages[this.$route.query.message]) {
      this.$message(messages[this.$route.query.message]);
    }

    // document.onreadystatechange = () => {
    //   if (document.readyState === "complete") {
    //     this.loading = false;
    //   }
    // };
  }

};
</script>

<style scoped lang="css">
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
