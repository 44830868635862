import { createStore } from "vuex";
import params from "./params";
import request from "./request";
import products from "./modules/products";
import cart from "./modules/cart";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: false });

export default createStore({
  state: {
    error: null,
    newPosts: [],
  },

  plugins: [
    createPersistedState({
      // key: "newPosts",
      // paths: ["products"],
      // storage: window.localStorage,
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],

  mutations: {
    SET_NEW_POSTS_TO_STATE: (state, newPosts) => {
      state.newPosts = newPosts;
    },
    setError(state, error) {
      state.error = error;
    },
    createError(state) {
      state.error = null;
    },
  },

  actions: {
    GET_NEW_POSTS_FROM_API({ commit }) {
      return axios(`${this.getters.params.apiUrl}api/new-posts-api`, {
        method: "POST",
      })
        .then((newPosts) => {
          commit("SET_NEW_POSTS_TO_STATE", newPosts.data);
          return newPosts;
        })
        .catch((error) => {
          console.warn("[NEW_POSTS]: " + error);
          return error;
        });
    },
  },

  getters: {
    NEW_POSTS(state) {
      return state.newPosts;
    },
    error: (s) => s.error,
  },
  modules: { params, request, products, cart },
});
