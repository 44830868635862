<template>
  <div class="" style="height: 100vh; width: 100%">
    <div class="text-center">
      <div class="app-loader">
        <div class="preloader-wrapper active" style="margin-top: 25vh;">
          <div class="spinner-layer" :class="color">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="gap-patch">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  computed: {
    color() {
      const colors = [
        "spinner-blue-only",
        "spinner-red-only",
        "spinner-green-only"
      ];
      return colors[Math.floor(Math.random() * 3)];
    }
  }
};
</script>
