export default {
  namespaced: true,

  state: {
    cart: [],
  },
  mutations: {
    SET_CART: (state, product) => {
      // if (state.cart.length) {
      if (state) {
        // console.log(store);
        let isProductExists = false;
        state.cart.map(function (item) {
          if (item.id === product.id) {
            isProductExists = true;
            item.qty++;
            product.qty++;
          }
        });
        if (!isProductExists) {
          // state.cart.push(product);
          product.qty = 1;
          // product.socQty = 0;
        }
      } else {
        // state.cart.push(product);
        product.qty = 1;
        product.socQty = 0;
      }
    },
    REMOVE_FROM_CART: (state, item, index) => {
      state.cart.splice(index, 1);
      item.qty = 0;
    },
    INCREMENT: (state, item) => {
      item.qty++;
    },
    DECREMENT: (state, item) => {
      if (item.qty > 1) {
        item.qty--;
      }
    },
  },
  actions: {
    ADD_TO_CART({ commit }, product) {
      commit("SET_CART", product);
    },

    DELETE_FROM_CART({ commit }, item, index) {
      commit("REMOVE_FROM_CART", item, index);
    },

    INCREMENT_CART_ITEM({ commit }, item, index) {
      commit("INCREMENT", item, index);
    },

    DECREMENT_CART_ITEM({ commit }, item, index) {
      commit("DECREMENT", item, index);
    },
  },
  getters: {
    CART(state) {
      return state.cart;
    },
  },
};
