<template>
  <div class="header" :class="{ 'scrolled-nav': scrolledNav }">
    <nav>
      <div class="branding">
        <img :src="require(`@/assets/img/logo/logo.png`)" alt="slide.alt" />
      </div>
      <!--ДЕСКТОПНАЯ ВЕРСИЯ-->
      <ul v-show="!mobile" class="navigation header-list-menu">
        <li>
          <router-link @click.prevent="$scrollToTop" :to="{ name: 'Home' }"
          ><i class="fad fa-home-alt"></i> Главная
          </router-link>
        </li>
        <li>
          <router-link @click.prevent="$scrollToTop" :to="{ name: 'Category' }"
          ><i class="far fa-store-alt"></i> Магазин
          </router-link>
        </li>
        <li>
          <router-link @click.prevent="$scrollToTop" :to="{ name: 'About' }"
          ><i class="fad fa-users"></i> О нас
          </router-link>
        </li>
        <!--        <li>-->
        <!--          <router-link @click.prevent="$scrollToTop" :to="{ name: 'Blog' }"-->
        <!--          ><i class="fad fa-fan"></i> Блог-->
        <!--          </router-link>-->
        <!--        </li>-->
        <li>
          <router-link @click.prevent="$scrollToTop" :to="{ name: 'Contact' }"
          ><i class="fad fa-id-card"></i> Контакты
          </router-link>
        </li>
        <li>
          <a><i class="fad fa-shopping-bag"></i> Покупателю </a>
          <ul class="ht-dropdown home-dropdown">
            <li>
              <router-link
                @click.prevent="$scrollToTop"
                :to="{ name: 'Payment' }"
              >Порядок оплаты
              </router-link>
            </li>
            <li>
              <router-link
                @click.prevent="$scrollToTop"
                :to="{ name: 'Delivery' }"
              >Оформление заказа и доставка
              </router-link>
            </li>
            <!--            <li>-->
            <!--              <router-link @click.prevent="$scrollToTop" :to="{ name: 'Refund' }"-->
            <!--                >Условия возврата-->
            <!--              </router-link>-->
            <!--            </li>-->
            <li>
              <router-link
                @click.prevent="$scrollToTop"
                :to="{ name: 'Policy' }"
              >Политика конфиденциальности
              </router-link>
            </li>
            <li>
              <router-link
                @click.prevent="$scrollToTop"
                :to="{ name: 'SocialProgram' }"
              >Социальная программа
              </router-link>
            </li>
            <li>
              <router-link
                @click.prevent="$scrollToTop"
                :to="{ name: 'Documents' }"
              >Сертификаты и свидетельства
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <Cart :cart_data="CART"></Cart>
        </li>
      </ul>
      <div class="cart-icon">
        <Cart :cart_data="CART"></Cart>
      </div>
      <div class="icon">
        <i
          @click="toggleMobileNav"
          v-show="mobile"
          id="icon"
          class="far fa-bars"
          :class="{ 'icon-active': mobileNav }"
        ></i>
      </div>
      <!--МОБИЛЬНАЯ ВЕРСИЯ-->
      <transition name="mobile-nav">
        <ul v-show="mobileNav" class="dropdown-nav header-list-menu">
          <li>
            <router-link @click.prevent="$scrollToTop" :to="{ name: 'Home' }"
            ><i class="fad fa-home-alt"></i> Главная
            </router-link>
          </li>
          <li>
            <router-link
              @click.prevent="$scrollToTop"
              :to="{ name: 'Category' }"
            ><i class="far fa-store-alt"></i> Магазин
            </router-link>
          </li>
          <li>
            <router-link @click.prevent="$scrollToTop" :to="{ name: 'About' }"
            ><i class="fad fa-users"></i> О нас
            </router-link>
          </li>
          <!--          <li>-->
          <!--            <router-link @click.prevent="$scrollToTop" :to="{ name: 'Blog' }"-->
          <!--              ><i class="fad fa-fan"></i> Блог-->
          <!--            </router-link>-->
          <!--          </li>-->
          <li>
            <router-link @click.prevent="$scrollToTop" :to="{ name: 'Contact' }"
            ><i class="fad fa-id-card"></i> Контакты
            </router-link>
          </li>
          <li>
            <a id="not-mobileNav-hide"
            ><i class="fad fa-shopping-bag"></i> Покупателю
            </a>
            <ul class="ht-dropdown home-dropdown position-static">
              <li>
                <router-link
                  @click.prevent="$scrollToTop"
                  :to="{ name: 'Payment' }"
                >Порядок оплаты
                </router-link>
              </li>
              <!--              <li>-->
              <!--                <router-link-->
              <!--                  @click.prevent="$scrollToTop"-->
              <!--                  :to="{ name: 'Delivery' }"-->
              <!--                  >Оформление заказа и доставка-->
              <!--                </router-link>-->
              <!--              </li>-->
              <!--              <li>-->
              <!--                <router-link @click.prevent="$scrollToTop" :to="{ name: 'Refund' }"-->
              <!--                  >Условия возврата-->
              <!--                </router-link>-->
              <!--              </li>-->
              <li>
                <router-link
                  @click.prevent="$scrollToTop"
                  :to="{ name: 'Policy' }"
                >Политика конфиденциальности
                </router-link>
              </li>
              <li>
                <router-link
                  @click.prevent="$scrollToTop"
                  :to="{ name: 'SocialProgram' }"
                >Социальная программа
                </router-link>
              </li>
              <li>
                <router-link
                  @click.prevent="$scrollToTop"
                  :to="{ name: 'Documents' }"
                >Сертификаты и свидетельства
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </transition>
    </nav>
  </div>
</template>

<script>
import Cart from "@/components/elements/Cart";
import { mapGetters } from "vuex";

export default {
  name: "navigation",
  data() {
    return {
      scrolledNav: null,
      mobile: false,
      mobileNav: false,
      windowWidth: null
    };
  },
  components: {
    Cart
    // MenuSettings,
  },
  computed: {
    ...mapGetters({
      CART: "products/CART"
    })
  },

  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
    var vm = this;

    document.addEventListener("click", function(e) {
      if (
        e.target !== document.getElementById("icon") &&
        e.target !== document.getElementById("not-mobileNav-hide")
      ) {
        vm.clickHandler(false);
      }
    });
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },

  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },

    clickHandler(e) {
      this.mobileNav = e;
    },

    updateScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 30) {
        this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;
      this.mobileNav = false;
    },

    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 850) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 100;
  width: 100%;
  position: fixed;
  transition: 0.5s ease all;
  //color: #fff;

  nav {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px 0;
    transition: 0.5s ease all;
    width: 90%;
    margin: 0 auto;

    @media (min-width: 1140px) {
      max-width: 1140px;
    }

    ul,
    .link {
      //font-weight: 500;
      //color: #fff;
      list-style: none;
      text-decoration: none;
    }

    li {
      text-transform: uppercase;
      //padding: 1px;
      padding-right: 15px;
      //margin-left: 16px;
    }

    a {
      font-size: 16px;
      transition: 0.5s ease all;
      padding-bottom: 4px;
      border-bottom: 1px solid transparent;

      &:hover {
        //color: #030000 !important;
        border-color: #f1ac06 !important;
      }
    }

    .link {
      font-size: 16px;
      transition: 0.5s ease all;
      padding-bottom: 4px;
      border-bottom: 1px solid #f1ac06 !important;

      &:hover {
        font-weight: 700;
        color: #030000;
        border-color: #f1ac06 !important;
      }
    }

    .branding {
      display: flex;
      align-items: center;

      img {
        width: 60px;
        transition: 0.5s ease all;
      }
    }

    .navigation {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: center;
    }

    .cart-icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 90px;
      height: 100%;
      color: #0d0d0d;
    }

    @media (min-width: 851px) {
      .cart-icon {
        display: none;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 30px;
      height: 100%;
      color: #0d0d0d;

      i {
        cursor: pointer;
        font-size: 24px;
        transition: 0.8s ease all;
      }
    }

    .icon-active {
      transform: rotate(180deg);
    }

    .dropdown-nav {
      display: flex;
      flex-direction: column;
      position: fixed;
      width: 100%;
      max-width: 250px;
      height: 100%;
      background-color: #fff;
      top: 0;
      left: 0;
      z-index: 1;

      li {
        margin-left: 0;

        .link {
          color: #000000;
        }
      }
    }

    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
      transition: 1s ease all;
    }

    .mobile-nav-enter-from,
    .mobile-nav-leave-to {
      transform: translateX(-250px);
    }

    .mobile-nav-enter-to {
      transform: translateX(0);
    }
  }

  .home-dropdown li {
    padding: 0;
  }
}

.scrolled-nav {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);

  nav {
    padding: 8px 0;

    .branding {
      img {
        transition: 1s ease all;
        width: 50px;
      }
    }
  }
}
</style>
