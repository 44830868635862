import axios from "axios";
// import products from "@/api/products";
// import { mapActions } from "vuex";

export default {
  namespaced: true,

  state: {
    categories: [],
    allProducts: [],
    offerProducts: [],
    partnerProducts: [],
    upperBannerProducts: [],
    newProducts: [],
    cart: [],
    ids: [],
  },

  mutations: {
    SET_CATEGORIES_TO_STATE(state, categories) {
      state.categories = categories;
    },

    SET_ALL_PRODUCTS_TO_STATE(state, allProducts) {
      state.allProducts = allProducts;
      state.allProducts.map(function (product) {
        product.inStock = 0;
        if (!product.inStock) {
          product.inStock = parseFloat(product.in_stock);
        }
      });
    },

    SET_NEW_PRODUCTS_TO_STATE(state, newProducts) {
      state.newProducts = newProducts;
    },

    SET_CART: (state, id) => {
      //Вычислиние наличия продукта
      state.allProducts.map(function (product) {
        if (product.id === id) {
          if (product.inStock > 0) {
            if (product.type === "weight") {
              product.inStock = product.inStock - 0.1;
              product.inStock = product.inStock.toFixed(1);
            } else {
              product.inStock = parseInt(product.inStock) - 1;
            }
          }
          console.log(product.in_stock);
          console.log(product.inStock);
        }
      });

      if (state.ids.length) {
        let isProductExists = false;
        state.ids.map(function (item) {
          if (item === id) {
            isProductExists = true;
            state.allProducts.map(function (product) {
              if (product.id === id) {
                console.log("Последующее добавление товара в корзину");
                product.qty++;
              }
            });
          }
        });

        //Первое добавление данного товара в корзину
        if (!isProductExists) {
          console.log("Первое добавление данного товара в корзину");
          state.ids.push(id);
          state.allProducts.map(function (product) {
            if (product.id === id) {
              product.inStock = parseFloat(product.in_stock);
              if (product.type === "weight") {
                product.inStock = product.inStock - 0.1;
                product.inStock = product.inStock.toFixed(1);
              } else {
                product.inStock = product.inStock - 1;
              }
              product.qty = 1;
              product.socQty = 0;
            }
          });
        }
      }

      //Открытие корзины
      else {
        console.log("Открытие корзины");
        state.ids.push(id);
        state.allProducts.map(function (product) {
          if (product.id === id) {
            product.inStock = parseFloat(product.in_stock);
            if (product.type === "weight") {
              product.inStock = product.inStock - 0.1;
              product.inStock = product.inStock.toFixed(1);
            } else {
              product.inStock = product.inStock - 1;
            }
            product.qty = 1;
            product.socQty = 0;
          }
        });
      }

      //Обновление корзины каждый раз при добавлении продукта
      state.cart = [];
      state.allProducts.map(function (product) {
        if (product.qty > 0 || product.socQty > 0) {
          state.cart.push(product);
        }
      });
    },

    REMOVE_FROM_CART: (state, item) => {
      item.qty = 0;
      item.socQty = 0;
      item.inStock = parseFloat(item.in_stock);
      state.ids.map(function (value, index) {
        if (value === item.id) {
          state.ids.splice(index, 1);
        }
      });
      state.cart.map(function (value, index) {
        if (value.id === item.id) {
          state.cart.splice(index, 1);
        }
      });
    },

    REMOVE_CART: (state) => {
      state.allProducts.map(function (product) {
        product.qty = 0;
        product.socQty = 0;
        product.inStock = parseFloat(product.in_stock);
      });
      state.ids = [];
      state.cart = [];
      localStorage.removeItem("myform");
      localStorage.removeItem("CART");
    },

    INCREMENT: (state, item) => {
      if (parseFloat(item.inStock) > 0) {
        item.qty++;
        if (item.type === "weight") {
          item.inStock = parseFloat(item.inStock) - 0.1;
          item.inStock = item.inStock.toFixed(1);
        } else {
          item.inStock = item.inStock - 1;
        }
      }
      state.allProducts.map(function (product) {
        if (product.id === item.id) {
          product.qty = item.qty;
          product.inStock = item.inStock;
        }
      });
    },

    INCREMENT_SOC: (state, item) => {
      if (parseFloat(item.inStock) > 0) {
        item.socQty++;
        if (item.type === "weight") {
          item.inStock = parseFloat(item.inStock) - 0.1;
          item.inStock = item.inStock.toFixed(1);
        } else {
          item.inStock = item.inStock - 1;
        }
      }
      state.allProducts.map(function (product) {
        if (product.id === item.id) {
          product.socQty = item.socQty;
          product.inStock = item.inStock;
        }
      });
    },

    DECREMENT: (state, item) => {
      if (item.qty > 0) {
        item.qty--;
        if (item.type === "weight") {
          item.inStock = parseFloat(item.inStock) + 0.1;
          item.inStock = item.inStock.toFixed(1);
        } else {
          item.inStock = item.inStock + 1;
        }
        state.allProducts.map(function (product) {
          if (product.id === item.id) {
            product.qty = item.qty;
            product.inStock = item.inStock;
          }
        });
      }
    },

    DECREMENT_SOC: (state, item) => {
      if (item.socQty > 0) {
        item.socQty--;
        if (item.type === "weight") {
          item.inStock = parseFloat(item.inStock) + 0.1;
          item.inStock = item.inStock.toFixed(1);
        } else {
          item.inStock = item.inStock + 1;
        }
        state.allProducts.map(function (product) {
          if (product.id === item.id) {
            product.socQty = item.socQty;
            product.inStock = item.inStock;
          }
        });
      }
    },
  },

  actions: {
    GET_CATEGORIES_FROM_API({ commit }) {
      return axios(`${this.getters.params.apiUrl}api/categories-api`, {
        method: "POST",
      })
        .then((categories) => {
          commit("SET_CATEGORIES_TO_STATE", categories.data);
          return categories;
        })
        .catch((error) => {
          console.warn("[CATEGORIES]: " + error);
          return error;
        });
    },

    GET_ALL_PRODUCTS_FROM_API({ commit }) {
      return axios(`${this.getters.params.apiUrl}api/all-products-api`, {
        method: "POST",
      })
        .then((allProducts) => {
          commit("SET_ALL_PRODUCTS_TO_STATE", allProducts.data);
          return allProducts;
        })
        .catch((error) => {
          console.warn("[All_PRODUCTS]: " + error);
          return error;
        });
    },

    GET_NEW_PRODUCTS_FROM_API({ commit }) {
      return axios(`${this.getters.params.apiUrl}api/new-products-api`, {
        method: "POST",
      })
        .then((newProducts) => {
          commit("SET_NEW_PRODUCTS_TO_STATE", newProducts.data);
          return newProducts;
        })
        .catch((error) => {
          console.warn("[NEW_PRODUCTS]: " + error);
          return error;
        });
    },

    // CART actions
    ADD_TO_CART({ commit }, id) {
      commit("SET_CART", id);
    },

    DELETE_FROM_CART({ commit }, item, index) {
      commit("REMOVE_FROM_CART", item, index);
    },

    DELETE_CART({ commit }, item, index) {
      commit("REMOVE_CART", item, index);
    },

    INCREMENT_CART_ITEM({ commit }, item, index) {
      commit("INCREMENT", item, index);
    },

    DECREMENT_CART_ITEM({ commit }, item, index) {
      commit("DECREMENT", item, index);
    },

    INCREMENT_CART_SOC_ITEM({ commit }, item, index) {
      commit("INCREMENT_SOC", item, index);
    },

    DECREMENT_CART_SOC_ITEM({ commit }, item, index) {
      commit("DECREMENT_SOC", item, index);
    },
  },

  getters: {
    CATEGORIES(state) {
      return state.categories;
    },

    ALL_PRODUCTS(state) {
      return state.allProducts;
    },

    OFFER_PRODUCTS(state) {
      return state.allProducts.filter((el) => el.is_offer === 1);
    },

    PARTNER_PRODUCTS(state) {
      return state.allProducts.filter((el) => el.partner_id >= 1);
    },

    UPPER_BANNER_PRODUCTS(state) {
      return state.allProducts.filter((el) => el.upper_banner === 1);
    },

    NEW_PRODUCTS(state) {
      //TODO:пока что получаю весь стейт, в дальнейшем надо доработать
      return state.allProducts;
    },

    CART(state) {
      // state.cart = state.allProducts.filter(
      //   (product) => product.qty >= 1 || product.socQty >= 1
      // );

      return state.cart;
      // }
    },
  },
};
