<template>
  <!--  <img-->
  <!--    :src="require(`@/assets/img/clipart-grass-crop-10.png`)"-->
  <!--    class="card-img-top"-->
  <!--    style="border-radius: 3px"-->
  <!--    alt="slide.alt"-->
  <!--  />-->
  <img
    :src="require(`/src/assets/img/clipart-grass-crop-10.png`)"
    class="card-img-top"
    style="border-radius: 3px"
    alt="slide.alt"
  />

  <div class="pt-3 pb-3 white">
    <div class="container">
      <div class="row container">
        <div class="single-footer">
          <ul>
            <li>
              <i class="fad fa-shield-alt"></i>
              <router-link to="/policy">
                Политика конфеденциальности
              </router-link>
            </li>
            <li>
              <i class="fad fa-credit-card"></i> Приём оплаты выполняется при
              помощи сервиса
              <a href="https://payanyway.ru/info/w/ru/public/welcome.htm">
                <img
                  :src="require(`@/assets/img/logo/payanyway.png`)"
                  style="width: 100px; margin-left: 3px"
                />
              </a>
            </li>
            <li>
              <i class="fad fa-box-check"></i>
              <router-link to="/delivery">
                Порядок оформления исполнения заказа
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom off-white-bg2">
    <div class="container">
      <div class="footer-bottom-content">
        <p class="copy-right-text">
          Copyright ©
          <a href="#"> "{{ siteName }}" {{ formatDate(date) }}г.</a>
          Все права защищены.
        </p>
        <!--        <div class="footer-social-content">-->
        <!--          <ul class="social-content-list">-->
        <!--            <li>-->
        <!--              <a href="#"><i class="fa fa-twitter"></i></a>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a href="#"><i class="fa fa-wifi"></i></a>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a href="#"><i class="fa fa-google-plus"></i></a>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a href="#"><i class="fa fa-facebook"></i></a>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a href="#"><i class="fa fa-youtube"></i></a>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </div>-->
      </div>
    </div>
    <!-- Container End -->
  </div>
</template>
<script>
export default {
  data: () => ({
    date: new Date()
  }),

  computed: {
    siteName() {
      return this.$store.getters.params.siteName;
    }
  },

  methods: {
    formatDate: (d) => d.toLocaleString("ru-RU").replace(",", "").slice(6, -8)
  }
};
</script>
