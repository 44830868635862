<template>
  <div class="col-md-6 col-lg-3 mb-3">
    <div class="single-product h-100 m-auto" style="max-width: 340px; min-width: 230px;">
      <Loader v-if="loading"></Loader>
      <div v-else>
        <span v-if="product.old_price" class="sticker-new rubik"
        >- {{ discount }} %</span
        >
        <!-- Product Image Start -->
        <div class="pro-img text-center zoom border-bottom" style="overflow: visible">
          <router-link
            @click.prevent="$scrollToTop"
            :to="{
              name: 'ProductDetail',
              params: { alias: product.alias },
            }"
          >
            <img :data-src="`../../../../img/products/${product.alias}/product-card-${product.id}.jpg`"
                 :src="require(`/src/assets/img/loading.gif`)"
                 :alt="product.alias"
            />
          </router-link>
        </div>

        <div class="pro-content">
          <div class="product-rating">
            <i v-for="r in ratingYes" :key="r" class="fa fa-star mr-1"></i>
            <i
              v-for="r in ratingNo"
              :key="r"
              class="i-color fa fa-star mr-1"
            ></i>
          </div>
          <h4>
            <router-link
              :to="{
                name: 'ProductDetail',
                params: { alias: product.alias },
              }"
            >{{ product.title }}
            </router-link>
          </h4>

          <p>
            <span class="price">Цена: {{ $cur(product.price) }}</span>
            <del v-if="product.old_price" class="prev-price text-danger">
              {{ $cur(product.old_price) }}
            </del>
            <br>
            <span v-if="product.type === 'weight' && parseFloat(product.inStock) !== 0" class="badge badge-success">В наличии: {{ product.inStock }} кг.</span>
            <span v-else-if="product.type === 'piece' && parseFloat(product.inStock) !== 0" class="badge badge-success">В наличии: {{ parseInt(product.inStock) }} шт.</span>
            <span v-else-if="parseFloat(product.inStock) === 0" class="badge badge-danger">Нет в наличии</span>
          </p>


          <div class="actions-secondary">
            <div>
              <button
                class="btn btn-warning rounded"
                type="button"
                @click="addToCart"
                title="Добавить товар в корзину"
                :class="{disabled: product.inStock <= 0}"
              >
                В корзину
              </button>

              <span v-if="allQty > 0" class="counter">{{ allQty }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  props: {
    product: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      loading: true,
      discount: null,
      ratingYes: null,
      ratingNo: null,
      id: this.product.id,
      qty: 0,
      socQty: 0,
      inStock: 0
    };
  },

  components: {},

  methods: {
    addToCart() {
      this.$emit("addToCart", this.product.id);
      this.$toast.success(
        `<i class="fas fa-cart-plus" style="font-size: 20px;"> ${this.product.title}</i>`
      );
      const vm = this;
      this.CART.map(function(cart_product) {
        if (cart_product.id === vm.id) {
          vm.qty = cart_product.qty;
          vm.socQty = cart_product.socQty;
          vm.allQty = vm.qty + vm.socQty;
          vm.inStock = cart_product.inStock;
        }
      });
    }
  },

  computed: {
    ...mapGetters({
      CART: ["products/CART"]
    }),
    allQty() {
      return this.product.qty + this.product.socQty;
    }
  },

  watch: {},

  mounted() {
    this.inStock = parseFloat(this.product.in_stock);
    this.qty = this.product.qty;
    this.socQty = this.product.socQty;
    this.allQty = this.qty + this.socQty;
    this.discount = parseInt(
      (this.product.old_price / this.product.price) * 100 - 100
    );
    this.ratingYes = this.product.rating;
    this.ratingNo = 5 - this.ratingYes;
    this.loading = false;
  }
};
</script>

<style lang="css" scoped>
.actions-secondary {
  position: absolute;
  bottom: 10px;
}

p {
  margin-bottom: 20px;
}

.i-color {
  color: lightgrey !important;
}
</style>

<style>
.counter {
  background-color: white;
  color: #000000;
  right: -5px !important;
  top: -15px;
}
</style>
