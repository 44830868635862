export default {
  state: {
    params: {
      siteName: "Православный Продукт",
      infoPhone: "8-800-200-58-90",
      infoEmail: "info@prav-product.ru",
      apiUrl: "https://prav-product.ru/",
      orderPickupPoint:
        "г. Ставрополь, ул. Рогожникова 17, магазин 'Церковная Трапеза', между 1 и 2 подъездами. Вход с обеих сторон дома.",
    },
  },

  mutations: {
    setParams(state, params) {
      state.params = params;
    },
    clearParams(state) {
      state.params = {};
    },
  },

  actions: {
    // async updateParams({ dispatch, commit, getters }, toUpdate) {
    //    try {
    //       const uid = await dispatch('getUid');
    //       const updateData = { ...getters.params, ...toUpdate };
    //       await firebase
    //          .database()
    //          .ref(`/users/${uid}/params`)
    //          .update(updateData);
    //       commit('setParams', updateData);
    //    } catch (e) {
    //       commit('setError', e);
    //       throw e;
    //    }
  },

  // async fetchParams({ dispatch, commit }) {
  //    try {
  //       const uid = await dispatch('getUid');
  //       const params = (
  //          await firebase.database().ref(`/users/${uid}/params`).once('value')
  //       ).val();
  //       commit('setParams', params);
  //    } catch (e) {
  //       commit('setError', e);
  //       throw e;
  //    }
  // },
  // },

  getters: {
    params: (s) => s.params,
  },
};
