export default {
    actions: {
        async request ({ commit }, {url, method = 'GET', data = null}) {
            try {
                const headers = {}
                let body
                if (data) {
                    headers['Content-Type'] = 'applications/json'
                    body = JSON.stringify(data)
                }
                const response = await fetch(url, {
                    method,
                    headers,
                    body
                })
                return await response.json()
            } catch (e) {
                commit('setError', e);
                console.warn('Error', e.message)
            }
        }
    },
};
