export default function (instance) {
  return {
    formLoad(payload) {
      return instance.post("api/checkout-api", payload);
    },
    isThereAnOrder(payload) {
      //return true/false
      return instance.post("api/is-there-an-order", payload);
    },
  };
}
