<template>
  <div>
    <div class="group-title"><h2>{{ title }}</h2></div>

  </div>
</template>

<script>
// import ProductWrapper from '@/components/elements/ProductWrapper';
export default {
  props: {
    title: String,
  },
  components: {
    // ProductWrapper
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>

</style>