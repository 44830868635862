import { createRouter, createWebHistory } from "vue-router";

// import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { layout: "main" },
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    props: true,
  },
  {
    path: "/about",
    name: "About",
    meta: { layout: "main" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    meta: { layout: "main" },
    component: () => import(/* webpackChunkName: "blog" */ "../views/Blog.vue"),
    props: true,
  },
  {
    path: "/post/:id",
    name: "Post",
    meta: { layout: "main" },
    component: () => import(/* webpackChunkName: "post" */ "../views/Post.vue"),
    props: true,
  },
  {
    path: "/contact",
    name: "Contact",
    meta: { layout: "main" },
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/payment",
    name: "Payment",
    meta: { layout: "main" },
    component: () =>
      import(/* webpackChunkName: "payment" */ "../views/Payment.vue"),
  },
  {
    path: "/delivery",
    name: "Delivery",
    meta: { layout: "main" },
    component: () =>
      import(/* webpackChunkName: "delivery" */ "../views/Delivery.vue"),
  },
  {
    path: "/refund",
    name: "Refund",
    meta: { layout: "main" },
    component: () =>
      import(/* webpackChunkName: "refund" */ "../views/Refund.vue"),
  },
  {
    path: "/policy",
    name: "Policy",
    meta: { layout: "main" },
    component: () =>
      import(/* webpackChunkName: "policy" */ "../views/Policy.vue"),
  },
  {
    path: "/search-result/:q",
    name: "SearchResult",
    meta: { layout: "main" },
    component: () =>
      import(
        /* webpackChunkName: "search-result" */ "../views/SearchResult.vue"
      ),
    props: true,
  },
  {
    path: "/product-detail/:alias",
    name: "ProductDetail",
    meta: { layout: "main" },
    component: () =>
      import(
        /* webpackChunkName: "product-detail" */ "../views/ProductDetail.vue"
      ),
    props: true,
  },
  {
    path: "/checkout",
    name: "Checkout",
    meta: { layout: "main" },
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/Checkout.vue"),
    props: true,
  },

  {
    path: "/checkout-test",
    name: "CheckoutTest",
    meta: { layout: "main" },
    component: () =>
      import(
        /* webpackChunkName: "checkout-test" */ "../views/CheckoutTest.vue"
      ),
    props: true,
  },

  {
    path: "/payment-page",
    name: "PaymentPage",
    meta: { layout: "main" },
    component: () =>
      import(/* webpackChunkName: "payment-page" */ "../views/PaymentPage.vue"),
    props: true,
  },

  {
    path: "/payment-page-test",
    name: "PaymentPageTest",
    meta: { layout: "main" },
    component: () =>
      import(
        /* webpackChunkName: "payment-page-test" */ "../views/PaymentPageTest.vue"
      ),
    props: true,
  },
  {
    path: "/social-program",
    name: "SocialProgram",
    meta: { layout: "main" },
    component: () =>
      import(
        /* webpackChunkName: "social-program" */ "../views/SocialProgram.vue"
      ),
    props: true,
  },
  {
    path: "/documents",
    name: "Documents",
    meta: { layout: "main" },
    component: () =>
      import(/* webpackChunkName: "documents" */ "../views/Documents.vue"),
    props: true,
  },
  {
    path: "/category",
    name: "Category",
    meta: { layout: "main" },
    component: () =>
      import(/* webpackChunkName: "category" */ "../views/Category"),
    props: true,
  },
  {
    path: "/payanyway-success-url",
    name: "PayanywaySuccessPage",
    meta: { layout: "main" },
    component: () =>
      import(
        /* webpackChunkName: "payanyway-success-page" */ "../views/payanyway/PayanywaySuccessPage"
      ),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
