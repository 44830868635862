<template>
  <GDialog v-model="value" max-width="1200">
    <div class="getting-started-example-styled">
      <div class="getting-started-example-styled__content">
        <div class="getting-started-example-styled__title">Корзина</div>

        <!--Section: Content-->
        <section v-if="cart_data.length" class="dark-grey-text">
          <!-- Shopping Cart table -->
          <div class="table-responsive">
            <table class="table product-table mb-0">
              <!-- Table head -->
              <thead class="mdb-color lighten-5">
              <tr>
                <th></th>
                <th class="font-weight-bold">
                  <strong>Продукт</strong>
                </th>

                <th class="font-weight-bold">
                  <strong>Цена</strong>
                </th>
                <th class="font-weight-bold">
                  <strong>Кол-во</strong>
                </th>
                <th class="font-weight-bold">
                  <strong>Соц. прог.</strong>
                </th>

                <th class="font-weight-bold">
                  <strong>Сумма</strong>
                </th>
                <th></th>
              </tr>
              </thead>
              <!-- /.Table head -->

              <!-- Table body -->
              <tbody>
              <CartProductCard
                v-on:value="value=$event"
                v-for="item in cart_data"
                :key="item.id"
                :cart_item_data="item"
                @deleteFromCart="deleteFromCart(item)"
                @increment="increment(item)"
                @decrement="decrement(item)"
                @incrementSoc="incrementSoc(item)"
                @decrementSoc="decrementSoc(item)"
              ></CartProductCard>
              </tbody>
            </table>
            <div class="d-flex justify-content-between align-items-center mt-3">
              <h4>
                <strong>Итого:</strong>
              </h4>
              <h4>
                <strong>
                  {{ $cur(cartTotalCost) }}
                </strong>
              </h4>

              <router-link
                type="button"
                class="btn btn-success rounded h-100"
                :to="{ name: 'Checkout' }"
                @click="value = null, isOrderIsset()"
              >Оформить заказ
              </router-link>

              <router-link
                v-if="is_admin"
                type="button"
                class="btn btn-purple rounded h-100"
                :to="{ name: 'CheckoutTest' }"
                @click="value = null, isOrderIsset()"
              >Оформить заказ
              </router-link>
            </div>
          </div>
        </section>

        <p v-else>Нет товаров в корзине...</p>
      </div>

      <div class="getting-started-example-styled__actions">
        <button @click="clearCart" class="btn btn-danger">Очистить</button>
        <button @click="value = null" class="btn btn-grey">Закрыть</button>
      </div>
    </div>
  </GDialog>

  <li @click="onOpen">
    <a type="button">
      <i class="fa fa-shopping-basket"></i>
      <span class="counter">{{ CART.length }}</span>
    </a>
  </li>
</template>

<script>
import "gitart-vue-dialog/dist/style.css";
import { GDialog } from "gitart-vue-dialog";
import CartProductCard from "@/components/elements/CartProductCard";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Cart",
  components: {
    CartProductCard,
    GDialog
  },
  props: {
    cart_data: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data: function() {
    return {
      value: false,
      is_admin: false
    };
  },

  async created() {
    const buyer = await this.$api.buyer.getBuyer();
    if (buyer.data.id === 6) {
      this.is_admin = true;
    }
  },

  computed: {
    ...mapGetters({
      CART: "products/CART",
      PRODUCTS: "products/ALL_PRODUCTS"
    }),
    cartTotalCost() {
      let result = [];
      if (this.cart_data.length) {
        for (let item of this.cart_data) {
          result.push(item.price * (item.qty + item.socQty));
        }
        result = result.reduce(function(sum, el) {
          return sum + el;
        });
        return parseFloat(result);
      } else {
        return 0;
      }
    }
  },

  methods: {
    ...mapActions({
      DELETE_FROM_CART: "products/DELETE_FROM_CART",
      INCREMENT_CART_ITEM: "products/INCREMENT_CART_ITEM",
      DECREMENT_CART_ITEM: "products/DECREMENT_CART_ITEM",
      INCREMENT_CART_SOC_ITEM: "products/INCREMENT_CART_SOC_ITEM",
      DECREMENT_CART_SOC_ITEM: "products/DECREMENT_CART_SOC_ITEM",
      DELETE_CART: "products/DELETE_CART"
    }),
    deleteFromCart(item) {
      this.DELETE_FROM_CART(item);
    },
    increment(item) {
      this.INCREMENT_CART_ITEM(item);
    },
    decrement(item) {
      this.DECREMENT_CART_ITEM(item);
    },
    incrementSoc(item) {
      this.INCREMENT_CART_SOC_ITEM(item);
    },
    decrementSoc(item) {
      this.DECREMENT_CART_SOC_ITEM(item);
    },
    onOpen() {
      this.value = true;
    },
    clearCart() {
      this.DELETE_CART();
      this.value = null;
      this.$router.push("/");
    },
    async isOrderIsset() {
      if (localStorage.getItem("myform")) {
        const isSetOrder = JSON.parse(localStorage.getItem("myform"));
        const res = await this.$api.checkoutForm.isThereAnOrder({ "id": isSetOrder.id });
        if (!res.data) {
          localStorage.removeItem("myform");
          localStorage.removeItem("CART");
          this.form = [];
          isSetOrder.remove();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
i {
  font-size: 25px !important;
}

.counter {
  font-family: "Rubik", sans-serif;
  background: #f1ac06 none repeat scroll 0 0;
  color: #fff;
  font-size: 12px;
  z-index: 1 !important;
}

.getting-started-example-styled {
  &__content {
    padding: 20px;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
}
</style>
