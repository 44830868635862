<template>
  <div class="group-title mt-2 mb-5">
    <h1 class="font-alice">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  },
  components: {},
  data() {
    return {};
  }
};
</script>

<style scoped>
h1 {
  font-size: 2em;
}
</style>
