<template>
  <div
    id="app"
    :style="`background: url(${require('@/assets/img/background/back1.png')})`"
  >
    <component :is="layout"></component>
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: true
    };
  },
  components: {
    MainLayout
  },
  async created() {

  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    }
  },
  // watch: {
  //   "$route.path"() {
  //     this.loading = false;
  //     console.log("Совершён переход по ссылке");
  //   }
  // },

  methods: {
    ...mapActions({
      GET_CATEGORIES_FROM_API: "products/GET_CATEGORIES_FROM_API",
      GET_ALL_PRODUCTS_FROM_API: "products/GET_ALL_PRODUCTS_FROM_API",
      GET_NEW_POSTS_FROM_API: "GET_NEW_POSTS_FROM_API",
      GET_NEW_PRODUCTS_FROM_API: "products/GET_NEW_PRODUCTS_FROM_API",
      DELETE_CART: "products/DELETE_CART"
    })
  },
  async mounted() {
    // this.timeNow = new Date().getTime();
    // this.timeStart = parseInt(localStorage.timeStart) + 3600000;
    // if (isNaN(this.timeStart)) {
    //   localStorage.setItem("timeStart", new Date().getTime());
    //   this.timeStart = parseInt(localStorage.timeStart) + 3600000;

    await this.GET_CATEGORIES_FROM_API();
    await this.GET_ALL_PRODUCTS_FROM_API();
    await this.GET_NEW_POSTS_FROM_API();
    await this.GET_NEW_PRODUCTS_FROM_API();

    //   console.log("timeStart перезаписан");
    // }
    //
    // if (this.timeNow >= this.timeStart) {
    //   localStorage.removeItem("timeStart");
    //   localStorage.setItem("timeStart", new Date().getTime());
    //   await this.GET_CATEGORIES_FROM_API();
    //   await this.GET_ALL_PRODUCTS_FROM_API();
    //   await this.GET_NEW_POSTS_FROM_API();
    //   await this.GET_NEW_PRODUCTS_FROM_API();
    //   console.log("$store перезагружен");
    // }
  }

};
</script>

<style lang="scss">
@import "assets/css/bootstrap.min.css";
@import "assets/mdb/css/mdb.min.css";
@import "assets/css/all.min.css";
@import "https://fonts.googleapis.com/css?family=Lily+Script+One";
//@import "assets/css/meanmenu.min.css";
//@import "assets/css/animate.css";
//@import "assets/css/jquery.fancybox.css";
@import "assets/css/default.css";
@import "assets/css/style.css";
@import "assets/css/media.css";
@import "assets/css/responsive.css";
//@import "assets/css/flexslider.css";
//@import "assets/css/category.css";
</style>
<style lang="css">
.invalid {
  font-size: 14px;
  color: #fd1f00;
}

.valid {
  font-size: 14px;
  color: #11af0c;
}
</style>
