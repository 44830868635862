import instance from "./instance";
import contactFormModule from "./contactForm";
import checkoutFormModule from "./checkoutForm";
import productsModule from "./products";
import blogModule from "./blog";
import searchModule from "./search";
import yandexModule from "./yandex";
import buyerModule from "./buyer";

export default {
  contactForm: contactFormModule(instance),
  checkoutForm: checkoutFormModule(instance),
  products: productsModule(instance),
  blog: blogModule(instance),
  search: searchModule(instance),
  yandex: yandexModule(instance),
  buyer: buyerModule(instance),
};
