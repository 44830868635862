<template>
  <!-- First row -->
  <tr>
    <th class="p-0 text-center">
      <div class="mt-3" style="height: 100%!important;">
        <router-link
          @click.prevent="reload(cart_item_data.alias)"
          :to="{
              name: 'ProductDetail',
              params: { alias: cart_item_data.alias },
            }"
        >
          <img :data-src="`../../../../img/products/${cart_item_data.alias}/mini-${cart_item_data.id}.jpg`"
               :src="require(`../../../../img/products/${cart_item_data.alias}/mini-${cart_item_data.id}.jpg`)"
               :alt="cart_item_data.alias"
          />
        </router-link>
      </div>

    </th>
    <td>
      <p class="text-muted">{{ cart_item_data.title }}</p>
    </td>

    <td style="min-width: 80px">
      <div class="d-inline-block">{{ $cur(this.cart_item_data.price) }}</div>
    </td>
    <td>
      <div class="cart_item__qty text-center" style="font-size: 20px">
        <span class="card qty_button" @click="incrementItem"> + </span>
        <span>{{ cart_item_data.qty }}</span>
        <span class="card qty_button" @click="decrementItem"> - </span>
      </div>
    </td>
    <td>
      <div class="cart_item__qty text-center" style="font-size: 20px">
        <span class="card qty_button" @click="incrementSocItem"> + </span>
        <span>{{ cart_item_data.socQty }}</span>
        <span class="card qty_button" @click="decrementSocItem"> - </span>
      </div>
    </td>

    <td class="font-weight-bold" style="min-width: 100px">
      {{ $cur(this.cart_item_data.price * (this.cart_item_data.qty + this.cart_item_data.socQty)) }}
    </td>
    <td>
      <button
        content="Удалить товар"
        @click="deleteFromCart"
        class="btn btn-danger rounded"
      >
        &times;
      </button>
    </td>
  </tr>
  <!-- /.First row -->
</template>

<script>


export default {
  name: "CartProductCard",
  props: {
    cart_item_data: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data: function() {
    return {};
  },

  computed: {},

  mounted() {
  },

  methods: {
    deleteFromCart() {
      this.$emit("deleteFromCart");
    },
    incrementItem() {
      this.$emit("increment");
    },
    decrementItem() {
      this.$emit("decrement");
    },
    incrementSocItem() {
      this.$emit("incrementSoc");
    },
    decrementSocItem() {
      this.$emit("decrementSoc");
    },
    reload: function(path) {
      location.replace("/product-detail/" + path);
    }
  }
};
</script>

<style scoped>
span {
  width: 90%;
}

.qty_button {
  width: 23px;
  margin: auto;
  cursor: pointer;
}

</style>
