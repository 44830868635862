import { createApp } from "vue";
import "./registerServiceWorker";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import messagePlugin from "./utils/message.plugin";
import Loader from "@/components/app/Loader";
import GlobalLoader from "@/components/app/GlobalLoader";
import TitlePage from "@/components/elements/TitlePage";
import TitleBox from "@/components/elements/TitleBox";
import ProductCard from "@/components/elements/ProductCard";
import Toaster from "@meforma/vue-toaster";
import VueSplide from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
import VueSnip from "vue-snip";
import ApiPlugin from "./plugins/api";
import MyLazy from "./plugins/lazyLoad";
import currencyFilter from "./plugins/currency";
import ImageUploader from "vue-image-upload-resize";
import VueLazyLoad from "vue3-lazyload";
import VueEasyLightbox from "vue-easy-lightbox";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import jQuery from "jquery";

window.$ = window.jQuery = jQuery;

app = createApp(App);
app.component("Loader", Loader);
app.component("GlobalLoader", GlobalLoader);
app.component("TitlePage", TitlePage);
app.component("TitleBox", TitleBox);
app.component("ProductCard", ProductCard);
app.component("Paginate", VPagination);
app
  .use(store)
  .use(router)
  .use(messagePlugin)
  .use(currencyFilter)
  .use(Toaster, { position: "bottom" })
  .use(VueSplide)
  .use(VueSnip)
  .use(ApiPlugin)
  .use(MyLazy)
  .use(ImageUploader)
  .use(VueLazyLoad)
  .use(VueEasyLightbox)
  .mount("#app");
app.config.globalProperties.$scrollToTop = () => window.scrollTo(0, 150);
let app;
