export default function (instance) {
  return {
    testProducts() {
      return instance.post("api/offer-api");
      // .then((res) => {
      //   this.$store
      //     .dispatch("products/setProducts", res.data)
      //     .then(() => {
      //       console.log(res);
      //     });
      // })
      // .catch((err) => {
      //   console.log(err + " Хрень какая-то происходит");
      // });
    },
    offerProducts() {
      return instance.post("api/offer-api");
    },
    upperBannerProducts() {
      return instance.post("api/upper-banner-api");
    },
    newProducts() {
      return instance.post("api/new-products-api");
    },
  };
}
